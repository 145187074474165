@import "../../styles/variables.scss";

.socialMedia {
  display: flex;
  left: 20px;
  position: absolute;
  top: 20px;
}

.socialMediaLink {
  background-color: #fff;
  border-radius: 100%;
  display: block;
  height: 30px;
  margin: 0 6px;
  overflow: hidden;
  text-indent: -999px;
  width: 30px;
}

.socialMediaLinkYt {
  background-image: url("../../files/youtube.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.socialMediaLinkFb {
  background-image: url("../../files/facebook.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  -webkit-font-smoothing: antialiased;
  background-image: url("../../files/background.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 600;
  height: 100vh;
  overflow: auto;
  text-rendering: optimizelegibility;
  text-shadow: 0 0 1px rgba(black, 0.6);

  .titleHeader {
    margin-top: 42px;
  }

  .svgTitle {
    text {
      fill: white;
      font-size: 18px;
      line-height: 1em;
      paint-order: stroke;
      stroke-linecap: butt;
      stroke-linejoin: round;
      stroke-width: 6px;
      stroke: black;
      text-anchor: middle;
      text-transform: uppercase;
    }
  }

  .results {
    background-color: rgba(black, 0.9);
    border-radius: 8px;
    color: white;
    font-size: 30px;
    padding: 32px;
  }

  .city {
    flex: 1 1 100%;
    order: 1;
    text-align: center;
    text-transform: uppercase;
  }

  .resultDone {
    .progressData {
      color: white;
    }

    .bar {
      background-color: #00aeef;
    }
  }

  .result .progressbar {
    flex: 1 1 100%;
    margin-bottom: 16px;
    order: 3;
    padding-top: 0;
    text-align: center;
  }

  .progress {
    background-color: transparent;
    border-radius: 25px;
    border: 6px solid transparent;
    box-shadow: 0px 0px 0px 2px white;
    overflow: hidden;
    position: relative;
  }

  .progressData {
    color: #00aeef;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }

  .bar {
    background-color: white;
    border-radius: 25px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: width 0.5s;
  }

  .peoples {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    order: 2;
    padding-top: 0;

    span {
      font-size: 45px;
      line-height: 1em;
      margin-right: 8px;
    }
  }

  .rowingIcon {
    fill: white;
    width: 60px;
  }

  .titleSub text {
    font-size: 32px;
  }
}

.counterContainer {
  display: flex;
  margin: 0 -16px;
  flex-wrap: wrap;
}

.counterSvgText {
  flex: 1 100%;
  text {
    fill: white;
    font-size: 10px;
    line-height: 1em;
    paint-order: stroke;
    stroke-linecap: butt;
    stroke-linejoin: round;
    stroke-width: 6px;
    stroke: black;
    text-anchor: end;
  }
}

.counters {
  display: flex;
  flex: 1 auto;
  justify-content: space-between;
}

.counter {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  margin: 0 6px;
  padding: 12px;

  &:last-of-type {
    margin-right: 0;
  }
}

.counterNumber {
  font-size: calc(42px + (62 - 42) * ((100vw - 320px) / (1920 - 320)));
  font-weight: bold;
}

.counterText {
  flex: 1 100%;
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
}

@media screen and (min-width: $small) {
  .container {
    .city {
      flex: 1 1 50%;
      text-align: left;
    }
    .result .progressbar {
      flex: 1 1 100%;
    }
    .peoples {
      justify-content: flex-end;
      flex: 1 1 50%;
      padding-top: 16px;
    }
  }
}

@media screen and (min-width: $medium) {
  .container {
    .city {
      flex-basis: 250px;
      max-width: 250px;
      order: 1;
      word-break: normal;
    }

    .result .progressbar {
      flex: 1;
      margin-bottom: 0;
      order: 2;
      padding-top: 16px;
    }

    .peoples {
      flex-basis: 200px;
      max-width: 200px;
      order: 3;
    }
  }

  .counterContainer {
    flex-wrap: nowrap;
  }

  .counterSvgText {
    flex: 1 auto;
  }

  .counters {
    flex: 0;
  }
}
