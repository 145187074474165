.dyplom {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}

.logo {
  height: 100px;
  width: 100px;
}

.button {
  position: relative;
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
