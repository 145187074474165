.content {
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}

.container {
  margin-top: 64px;
  padding-bottom: 32px;
  padding-top: 32px;
}
