.dashboardHeader {
  .appBar {
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    z-index: 1201;
  }

  .appBarShift {
    margin-left: 240px;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: calc(100% - 240px);
  }

  .menuButtonHidden {
    display: none;
  }

  .logoLink {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .logo {
    height: 40px;
  }
}
