@import "../../../styles/variables.scss";

.dashboardMenu {
  display: flex;

  .drawerPaper {
    width: 240px;
    position: relative;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    white-space: nowrap;
  }

  .drawerPaperClose {
    width: 56px;
    overflow-x: hidden;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .toolbarIcon {
    display: flex;
    padding: 0 8px;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (min-width: $small) {
  .dashboardMenu {
    .toolbarIcon {
      min-height: 64px;
    }
  }
}
